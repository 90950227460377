.form-container {
    display: flex;
    align-items : center;
    flex-direction : column;
    justify-content : space-evenly;
}


.body-container {
    display: "flex";
    align-items : "center";
    justify-content : "center";
}

.body-container h1 {
    text-align: center;
}
