.card-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.details-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}

.detail-name {
  color: #115f96;
  font-weight: 700;
}

.detail-information {
  margin: 0px;
  font-size: 0.95rem;
  line-height: 1.25;
  letter-spacing: 0.035em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(60, 58, 58);
  font-weight: 700;
}

p {
  color: #115f96;
  margin-bottom: 0;
  font-weight: 700;
}
p span {
  margin: 0px;
  font-size: 0.95rem;
  line-height: 1.25;
  letter-spacing: 0.035em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(60, 58, 58);
  font-weight: 700;
}

textarea {
  margin: 0px;
  font-size: 0.85rem;
  line-height: 1.25;
  letter-spacing: 0.035em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(74, 72, 72);
  font-weight: 700;
  padding: 0.35rem;
}

.textArea-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.processed-candidate {
  display: flex;
  align-items: center;
  gap: 1rem;
}
