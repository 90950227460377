.dashboard-content-form {
    display: flex;
    width: 40vw;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    margin: 0.73rem;
;}

.dashboard-content-input {
    font-style: inherit;
    font-variant: inherit;
    font-weight: inherit;
    font-stretch: inherit;
    line-height: inherit;
    font-family: inherit;
    letter-spacing: inherit;
    border-radius: 0.375rem;
    border: 1px solid rgb(163, 160, 160);
    box-sizing: content-box;
    background: none transparent;   
    margin: 0px;
    display: block;
    min-width: 0px;
    width: 30vw;
    color: #000000;
    font-size: 0.75rem;
    padding: 0.625rem;
};
