.dashboard-header-container {
    padding: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.dashboard-header-left {
        padding: 0.8em;
        padding-left: 2em;
        padding-right: 2em;
        font-size: 0.9rem;
        font-weight: 700;
        text-transform: uppercase;
        border-bottom: 2px solid #015C92;
}
.dashboard-header-btn {
    padding: 0.8em;
    padding-left: 2em;
    padding-right: 2em;
    cursor: pointer;
    color: #FFFFFF;
    font-size: 0.9rem;
    font-weight: 700;
    border-radius: 8px;
    text-transform: uppercase;
    border: 1px solid #015C92;
    background-color: #015C92;
}
.dashboard-header-btn:hover {
    color: #015C92;
    border: 1px solid #015C92;
    background-color: #F0F2F5;
}
.dashboard-header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dashboard-header-icon {
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
}
.dashboard-header-avatar {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 20px;
}