.dashboard-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
}
.dashboard-content-container {
    margin: 1em;
    background-color: white;
    border: none;
    border-radius: 10px;
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap:25px;
    overflow: auto;
}
.dashboard-content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dashboard-content-header h2 {
    margin-top: auto;
    margin-bottom: auto;
    color: black;
    font-size: xx-large;
    font-weight: 700;
}
.dashboard-content-btn {
    padding: 0.7em;
    padding-left: 2em;
    padding-right: 2em;
    cursor: pointer;
    color: #FFFFFF;
    font-size: 0.9rem;
    font-weight: 700;
    border-radius: 8px;
    text-transform: uppercase;
    border: 1px solid #015C92;
    background-color: #015C92;
}
.dashboard-content-btn:hover {
    color: #015C92;
    border: 1px solid #015C92;
    background-color: #F0F2F5;
}
.dashboard-content-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
}
.dashboard-content-search {
    font-weight: 400;
    line-height: 1.4em;
    letter-spacing: 0.009em;
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    background-color: transparent;
    font-size: 0.875rem;
    border-radius: 0.375rem;
    border: 1px solid gray;
}
.dashboard-content-search:focus {
    outline: #1A76D2 auto 1px;
}
.dashboard-content-input {
    font-style: inherit;
    font-variant: inherit;
    font-weight: inherit;
    font-stretch: inherit;
    line-height: inherit;
    font-family: inherit;
    letter-spacing: inherit;
    border-radius: 0.375rem;
    border: 1px solid rgb(163, 160, 160);
    box-sizing: content-box;
    background: none transparent;   
    margin: 0px;
    display: block;
    min-width: 0px;
    width: 100%;
    color: #000000;
    font-size: 0.75rem;
    padding: 0.625rem;
}
table {
    width: 100%;
    margin: auto;
}
th {
    text-align: left;
    padding: 12px 24px;
    background: transparent;
    box-shadow: none;
    color: #2D83B5;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: 0.035em;
    border-bottom: 0.05rem solid rgb(240, 242, 245);
}
tbody {
    display: table-row-group;
}
tr, thead {
    color: inherit;
    display: table-row;
    vertical-align: middle;
    outline: 0px;
}
td {
    text-align: left;
    padding: 12px 24px;
    background: transparent;
    box-shadow: none;
    border-bottom: 0.05rem solid rgb(216, 218, 223);
}
td div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.state-tag{
    text-align: center;
}

.dashboard-content-avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 20px;
}
.td-span {
    margin: 0px;
    font-size: 0.75rem;
    line-height: 1.25;
    letter-spacing: 0.035em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: gray;
    font-weight: 700;
}
.dashboard-content-icon {
    width: 20px;
    margin-right: 10px;
}
.dashboard-content-footer {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: center;
}
.dashboard-content-footer .pagination {
    padding: 0.5rem;
    margin: 0.2rem;
    border-radius: 5px;
    background-color: #F0F2F5;
    cursor: pointer;
}
.dashboard-content-footer .active-pagination {
    padding: 0.5rem;
    margin: 0.2rem;
    border-radius: 5px;
    color: #FFFFFF;
    background-color: #2D83B5;
    cursor: pointer;
}
.empty-table {
    margin: 0px;
    font-size: 1rem;
    line-height: 1.25;
    letter-spacing: 0.035em;
    color: gray;
    font-weight: 700;
    border-color: #FFFFFF;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/* From uiverse.io by @adamgiebl */
/* The switch - the box around the slider */
.switch {
    font-size: 0.75rem;
    position: relative;
    display: inline-block;
    width: 3.5em;
    height: 2em;
   } 
   
   label span{
    font-size: 0.75rem;
   }
   /* Hide default HTML checkbox */
   .switch input {
    opacity: 0;
    width: 0;
    height: 0;
   }
   
   /* The slider */
   .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 30px;
   }
   
   .slider:before {
    position: absolute;
    content: "";
    height: 1.4em;
    width: 1.4em;
    border-radius: 20px;
    left: 0.3em;
    bottom: 0.3em;
    background-color: white;
    transition: .4s;
   }
   
   input:checked + .slider {
    background-color: #2196F3;
   }
   
   input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
   }
   
   input:checked + .slider:before {
    transform: translateX(1.5em);
   }